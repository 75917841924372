import React, { Component } from "react";
import {
  Input,
  Button,
  Spin,
  Divider,
  Select,
  Popconfirm,
  message,
  Radio,
  Space,
  Checkbox,
  InputNumber,
} from "antd";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { connect } from "react-redux";
import "react-phone-number-input/style.css";
import instance from "../../store/actions/instance";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { storeCategories } from "./storeCategories";
import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import SuccessModal from "./SuccessModal";

const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const validateSubdomain = (val, token) =>
  instance.post(`/check_reseller_subdomain_availability/`, {
    subdomain: val,
    authToken: token,
  });
const searchDebounced = AwesomeDebouncePromise(validateSubdomain, 500);

const validateEmail = (val) => instance.get(`/check_user_availability/${val}/`);
const searchDebouncedUser = AwesomeDebouncePromise(validateEmail, 1000);

class UserInfo extends Component {
  state = {
    storeName: "",
    fullName: "",
    email: "",
    phone: "",
    storeType: 1,
    storeCategory: undefined,
    subdomain: "",
    domainValid: false,
    emailValid: false,
    domainLoading: false,
    emailValidLoading: false,
    createStoreLoading: false,
    didAttemptGenerating: false,
    response: null,
    with_deliverect: false,
    with_b2b: false,
    package: "basic",
    foodicsCustomerRef: "",
    foodicsInvoiceRef: "",
    foodicsPackage: 1,
    foodicsExtraBranches: false,
    foodicsExtraBranchesCount: 0,
  };

  checkDomain = async (query) => {
    const { registrationData } = this.props;
    if (!query) {
      return;
    }
    this.setState({ domainLoading: true });
    try {
      const res = await searchDebounced(query, registrationData.authToken);

      if (
        this.state.subdomain.length &&
        /^(?:(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,6}$/.test(
          `${this.state.subdomain}.${registrationData.baseDomain}`
        ) &&
        !this.state.subdomain.includes(".")
      )
        this.setState({ domainValid: !!res.data });
      else this.setState({ domainValid: false });
    } catch (e) {
      console.error(e);
    }
    this.setState({ domainLoading: false });
  };

  checkEmail = async (query) => {
    if (!query) {
      return;
    }
    this.setState({ emailLoading: true });
    try {
      const res = await searchDebouncedUser(query);

      if (
        this.state.email?.trim()?.length &&
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
          this.state.email
        )
      )
        this.setState({ emailValid: !!res.data });
      else this.setState({ emailValid: false });
    } catch (e) {
      console.error(e);
    }
    this.setState({ emailLoading: false });
  };

  onChangeSubdomain = (e) => {
    const { registrationData } = this.props;
    this.setState({ subdomain: e.target.value });
    if (e.target.value?.trim()?.length) {
      if (
        /^(?:(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,6}$/.test(
          `${e.target.value}.${registrationData.baseDomain}`
        ) &&
        !e.target.value.includes(".")
      )
        this.checkDomain(e.target.value.trim());
      else this.setState({ domainValid: false });
    } else this.setState({ domainValid: false });
  };

  renderSubdomainSuffix = () => {
    const { domainValid, domainLoading } = this.state;
    if (domainLoading) return <Spin indicator={antIcon} />;
    if (this.state.subdomain) {
      if (domainValid) return <CheckOutlined style={{ color: "green" }} />;
      else return <CloseOutlined style={{ color: "red" }} />;
    }
    return <></>;
  };

  renderEmailSuffix = () => {
    const { emailValid, emailLoading } = this.state;
    if (emailLoading) return <Spin indicator={antIcon} />;
    if (this.state.email) {
      if (emailValid) return <CheckOutlined style={{ color: "green" }} />;
      else {
        if (
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
            this.state.email
          )
        )
          return <CloseOutlined style={{ color: "red" }} />;
        else return <></>;
      }
    }
    return <></>;
  };

  getValidationErrors = () => {
    let errors = [];
    const reseller = this.props.registrationData.reseller;
    const s = this.state;
    if (!s.storeName?.trim()?.length) errors.push("Enter the brand name");
    if (!s.storeCategory?.trim()?.length)
      errors.push("Enter the brand category");
    if (!s.fullName?.trim()?.length)
      errors.push("Enter the client's full name");
    if (!s.email?.trim()?.length || !s.emailValid)
      errors.push("Enter a valid email");
    if (!s.phone?.trim()?.length || !isValidPhoneNumber(s.phone?.trim()))
      errors.push("Enter the client's phone number");
    if (!s.domainValid) errors.push("Enter a valid domain");
    if (reseller === "foodics" && !this.state.foodicsCustomerRef.trim()?.length)
      errors.push("Enter the customer reference");
    if (reseller === "foodics" && !this.state.foodicsInvoiceRef.trim()?.length)
      errors.push("Enter the invoice reference");
    return errors;
  };

  createStore = async () => {
    this.setState({ createStoreLoading: true, didAttemptGenerating: true });
    if (this.getValidationErrors().length) {
      this.setState({ createStoreLoading: false });
      return;
    }
    try {
      const res = await instance.post("/create_reseller_store/", {
        authToken: this.props.registrationData.authToken,
        ...this.state,
      });
      if (res.data.success && res.data.store_url) {
        setTimeout(
          () =>
            this.setState({ response: res.data, createStoreLoading: false }),
          5000
        );
      } else {
        message.error(res.data.message || "Something went wrong");
        this.setState({ createStoreLoading: false });
      }
    } catch (e) {}
  };

  onChangeEmail = (e) => {
    this.setState({ email: e.target.value });
    if (e.target.value?.trim()?.length) {
      if (
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
          e.target.value
        )
      )
        this.checkEmail(e.target.value.trim());
      else this.setState({ emailValid: false });
    } else this.setState({ emailValid: false });
  };
  render() {
    const { registrationData } = this.props;
    const { loading, data } = this.state;
    const inputStyle = {
      paddingTop: 13,
      paddingBottom: 13,
    };
    const reseller = registrationData.reseller;
    return (
      <div className="my-4">
        {!!this.state.response && (
          <SuccessModal
            response={this.state.response}
            onCreateAnotherStore={() => {
              this.setState({
                storeName: "",
                fullName: "",
                email: "",
                phone: "",
                storeCategory: undefined,
                subdomain: "",
                domainValid: false,
                emailValid: false,
                domainLoading: false,
                emailValidLoading: false,
                createStoreLoading: false,
                didAttemptGenerating: false,
                response: null,
              });
            }}
          />
        )}
        <div
          className="mx-auto"
          style={{ maxWidth: 400, width: "calc(100% - 66px)" }}
        >
          <div className="text-left">
            <p className="text-left bold">Store and Client Info</p>
            <Input
              placeholder="Brand name"
              style={{ paddingTop: 13, paddingBottom: 13, marginBottom: 15 }}
              onChange={(e) => this.setState({ storeName: e.target.value })}
              value={this.state.storeName}
            />
            <div>
              <Select
                className="w-100"
                showSearch
                placeholder="Select a category"
                optionFilterProp="children"
                onChange={(v) => this.setState({ storeCategory: v })}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                defaultValue={undefined}
                style={{ marginBottom: 15 }}
                value={this.state.storeCategory}
              >
                {storeCategories.map((cat) => (
                  <Option value={cat}>{cat}</Option>
                ))}
              </Select>
            </div>
            <Input
              placeholder="Client's full name"
              style={{ paddingTop: 13, paddingBottom: 13, marginBottom: 15 }}
              onChange={(e) => this.setState({ fullName: e.target.value })}
              value={this.state.fullName}
            />
            <Input
              placeholder="Client's email"
              style={{ paddingTop: 13, paddingBottom: 13, marginBottom: 15 }}
              onChange={this.onChangeEmail}
              suffix={this.renderEmailSuffix()}
              value={this.state.email}
            />
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              defaultCountry={
                registrationData.reseller === "enable" ||
                registrationData.reseller === "restec"
                  ? "QA"
                  : "KW"
              }
              onChange={(e) => this.setState({ phone: e })}
              value={this.state.phone}
            />
            {reseller === "foodics" && (
              <Input
                placeholder="Foodics Customer Reference"
                style={{
                  paddingTop: 13,
                  paddingBottom: 13,
                  marginTop: 15,
                }}
                onChange={(e) =>
                  this.setState({ foodicsCustomerRef: e.target.value })
                }
                value={this.state.foodicsCustomerRef}
              />
            )}
            {reseller === "foodics" && (
              <Input
                placeholder="Foodics Invoice Reference"
                style={{
                  paddingTop: 13,
                  paddingBottom: 13,
                  marginBottom: 15,
                  marginTop: 15,
                }}
                onChange={(e) =>
                  this.setState({ foodicsInvoiceRef: e.target.value })
                }
                value={this.state.foodicsInvoiceRef}
              />
            )}
            {registrationData.reseller !== "shoplink" && (
              <Divider style={{ marginTop: 24, marginBottom: 16 }} />
            )}{" "}
            <div>
              {["hesabe", "enable"].includes(registrationData.reseller) && (
                <>
                  <p className="text-left bold">Store Type</p>
                  <Radio.Group
                    onChange={(e) => {
                      this.setState({
                        storeType: e.target.value,
                      });
                    }}
                    defaultValue={1}
                  >
                    <Space direction="vertical">
                      <Radio value={1}>Online Ordering</Radio>

                      <Radio value={2}>QR Menu</Radio>
                    </Space>
                  </Radio.Group>
                </>
              )}

              {["foodics"].includes(registrationData.reseller) && (
                <>
                  <p className="text-left bold">Store Package</p>
                  <Radio.Group
                    onChange={(e) => {
                      this.setState({
                        foodicsPackage: e.target.value,
                      });
                    }}
                    defaultValue={1}
                  >
                    <Space direction="vertical">
                      <Radio value={1}>Basic</Radio>

                      <Radio value={2}>Advanced</Radio>
                    </Space>
                  </Radio.Group>
                  <p className="text-left bold mt-3">Add-ons</p>
                  <Checkbox
                    onChange={(e) =>
                      this.setState({ foodicsExtraBranches: e.target.checked })
                    }
                  />
                  <span className="mx-2">Extra branches</span>

                  {this.state.foodicsExtraBranches && (
                    <div className="mt-3">
                      <p>How many?</p>
                      <InputNumber
                        defaultValue={0}
                        value={this.state.foodicsExtraBranchesCount}
                        onChange={(e) =>
                          this.setState({ foodicsExtraBranchesCount: e })
                        }
                      />
                    </div>
                  )}
                </>
              )}

              {registrationData.reseller === "restec" && (
                <>
                  <p className="text-left bold">Store Package</p>
                  <Radio.Group
                    onChange={(e) => {
                      this.setState({
                        package: e.target.value,
                      });
                    }}
                    defaultValue={"basic"}
                  >
                    <Space direction="vertical">
                      <Radio value={"basic"}>Basic</Radio>

                      <Radio value={"advanced"}>Advanced</Radio>
                    </Space>
                  </Radio.Group>
                  <p className="text-left bold mt-3">Deliverect</p>
                  <Radio.Group
                    onChange={(e) => {
                      this.setState({
                        with_deliverect: e.target.value,
                      });
                    }}
                    defaultValue={false}
                  >
                    <Space direction="vertical">
                      <Radio value={false}>Without Deliverect</Radio>

                      <Radio value={true}>With deliverect</Radio>
                    </Space>
                  </Radio.Group>

                  <p className="text-left bold mt-3">B2B</p>
                  <Radio.Group
                    onChange={(e) => {
                      this.setState({
                        with_b2b: e.target.value,
                      });
                    }}
                    defaultValue={false}
                  >
                    <Space direction="vertical">
                      <Radio value={false}>Without B2B</Radio>

                      <Radio value={true}>With B2B</Radio>
                    </Space>
                  </Radio.Group>
                </>
              )}
            </div>
            <Divider style={{ marginTop: 24, marginBottom: 16 }} />
            <div style={{ whiteSpace: "nowrap" }} className="mt-3">
              <p className="text-left bold">Domain</p>
              <Input
                placeholder="Subdomain"
                style={{ width: "72%", ...inputStyle, float: "left" }}
                onChange={this.onChangeSubdomain}
                suffix={this.renderSubdomainSuffix()}
                value={this.state.subdomain}
              ></Input>
              <span style={{ lineHeight: 3.8 }}>
                .{registrationData.baseDomain}
              </span>
            </div>
            {this.getValidationErrors().length > 0 &&
              this.state.didAttemptGenerating && (
                <div>
                  <Divider />
                  <p className="text-left bold">Errors</p>
                  <ul>
                    {this.getValidationErrors().map((error) => (
                      <li style={{ color: "red" }}>{error}</li>
                    ))}
                  </ul>
                </div>
              )}
            {!this.state.response && (
              <Popconfirm
                title="Are you sure you want to create the store with the given details?"
                onConfirm={() => this.createStore()}
                okText="Yes"
                cancelText="No"
                disabled={
                  this.getValidationErrors().length > 0 &&
                  this.state.didAttemptGenerating
                }
              >
                <Button
                  className="py-3 mb-4 w-100"
                  style={{
                    backgroundColor:
                      this.getValidationErrors().length > 0 &&
                      this.state.didAttemptGenerating
                        ? "#ebebeb"
                        : "#0099cc",
                    color:
                      this.getValidationErrors().length > 0 &&
                      this.state.didAttemptGenerating
                        ? "#d6d6d6"
                        : "white",
                    height: 60,
                    border: "none",
                    marginTop: 30,
                    width: "100%",
                  }}
                  disabled={
                    this.getValidationErrors().length > 0 &&
                    this.state.didAttemptGenerating
                  }
                  loading={this.state.createStoreLoading}
                >
                  CREATE STORE
                </Button>
              </Popconfirm>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    registrationData: state.registrationData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
