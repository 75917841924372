import { Button, Modal } from "antd";
import { useState } from "react";
import { ReactComponent as Success } from "./assets/check.svg";

const App = ({ onCreateAnotherStore, response }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      visible={true}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      footer={null}
      onCancel={() => onCreateAnotherStore()}
    >
      <div className="p-3 text-center">
        <div>
          <Success style={{ width: 120, height: 120 }} />
        </div>

        <div className="mt-4">
          <h3 style={{ fontWeight: "bold" }}>Store created successfully!</h3>
          <p>Here are the login details:</p>
          <div
            className="text-left"
            style={{
              maxWidth: response.store_url.length * 10 - 10,
              margin: "auto",
            }}
          >
            <p className="m-0">
              <span style={{ fontWeight: "bold" }}>URL:</span>{" "}
              <a href={`${response.store_url}manage`} target="_blank">
                {response.store_url}
              </a>
            </p>
            <p className="m-0">
              <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
              {response.email}
            </p>
            <p className="m-0">
              <span style={{ fontWeight: "bold" }}>Password:</span>{" "}
              {response.password}
            </p>
          </div>

          <Button
            type="primary"
            className="mt-4"
            style={{ background: "#0099cc", borderColor: "#0099cc" }}
            onClick={onCreateAnotherStore}
          >
            Create another store
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default App;
